import { configureStore } from '@reduxjs/toolkit'

import { api } from './api'
// one central api per base URL is recommended by RTK guide
// https://redux-toolkit.js.org/rtk-query/overview#apis
// Correct Store Configuration for Code Splitting: https://github.com/reduxjs/redux-toolkit/discussions/2506

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false
    }).concat([api.middleware])
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
